import dynamic from 'next/dynamic';
import Modal from '@components/ui/modal/modal';
import { useModalAction, useModalState } from './modal.context';
const LoginRegister = dynamic(() => import('@framework/auth/login-register'));
const ForgotPassword = dynamic(() => import('@framework/auth/forget-password'));
const ResetPassword = dynamic(() => import('@framework/auth/reset-password'));
const ProductDetailsModalView = dynamic(
  () => import('@framework/products/product'),
  { ssr: false }
);
const ShopInfoCard = dynamic(() => import('@components/shops/sidebar'));
const CreateOrUpdateAddressForm = dynamic(
  () => import('@framework/address/create-or-update')
);
const AddressDeleteView = dynamic(
  () => import('@framework/address/delete-view')
);
const RatingsShop = dynamic(
  () => import('@framework/shops/ratings-shop')
);

const ImageZoomPopup = dynamic(
  () => import("@components/claims/image-zoom-popup")
);

const ConfirmCancelClaimsPopup = dynamic(
  () => import("@components/claims/confirm-cancel-claims-popup")
);

const SearchPopup = dynamic(
  () => import("@components/layouts/mobile/search-popup")
);

const ServicePopup = dynamic(
  () => import("@components/services/service-popup")
);

const ShopNotePopup = dynamic(() => import('@framework/cart/shop-note-popup'));
const OrderProductDeletedPopup = dynamic(() => import('@framework/orders-history/order-product-deleted-popup'));
const DeleteShopPopup = dynamic(() => import('@framework/orders-history/ultils/delete-shop-popup'));

const ManagedModal = () => {
  const { isOpen, view, data } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Modal open={isOpen} onClose={closeModal} view={view}>
      {view === 'LOGIN_VIEW' && <LoginRegister defaultIndex={data?.defaultIndex || 0}/>}
      {view === 'FORGOT_VIEW' && <ForgotPassword />}
      {view === 'RESET_PASSWORD_VIEW' && <ResetPassword />}
      {view === 'ADD_OR_UPDATE_ADDRESS' && <CreateOrUpdateAddressForm />}
      {view === 'DELETE_ADDRESS' && <AddressDeleteView />}
      {view === 'PRODUCT_DETAILS' && (
        <ProductDetailsModalView product={data?.product} productSlug={data?.slug} productDetailPath={data?.productDetailPath}/>
      )}
      {view === 'SHOP_INFO' && (
        <ShopInfoCard
          data={data}
          cardClassName="!hidden"
          className="!flex flex-col !w-screen !h-screen max-w-screen-sm"
        />
      )}

      {view === 'RATES_SHOP' && <RatingsShop orderId={data?.orderId} shopId={data?.shopId} shopName={data?.shopName}/>}
      {view === "ZOOM_IMAGE_POPUP" && <ImageZoomPopup />}
      {view === "CONFIRM_CANCEL_CLAIMS_POPUP" && <ConfirmCancelClaimsPopup />}
      {view === "SEARCH_POPUP" && <SearchPopup />}
      {view === "SERVICE_DETAIL_POPUP" && <ServicePopup slug={data?.slug} />}
      {view === "SHOP_NOTE_POPUP" && <ShopNotePopup />}
      {view === "ORDER_PRODUCT_DELETED_POPUP" && <OrderProductDeletedPopup />}
      {view === "DELETE_SHOP_ORDER_POPUP" && <DeleteShopPopup />}
    </Modal>
  );
};

export default ManagedModal;
