import { CoreApi } from '@framework/utils/core-api';
import { API_ENDPOINTS } from '@framework/utils/endpoints';

export type AddToCartInputType = {
  productId: string | number;
  quantity: string | number;
  optionId: string | number;
};

export type UpdateItemInputType = {
  id: string | number;
  quantity: string | number;
};

export type UpdateCartItemInputType = {
  id: string | number;
};

export type DeleteCartShopType = {
  cartShopIds: number[] | string[];
}

export type UpdateNotesShopInputType = {
  id: string | number;
  cart_shops: any;
};

class Cart extends CoreApi {
  constructor(_base_path: string) {
    super(_base_path);
  }

  all(locale: string | undefined = "vi") {
    return this.http
      .get(`${this._base_path}?excludeCartItems=1`, {
        headers: {
          locale_code: locale
        }
      })
      .then((res) => res.data);
  }

  addToCartProduct(input: AddToCartInputType) {
    return this.http
      .post(API_ENDPOINTS.ADD_TO_CART_PRODUCT, input)
      .then((res) => res.data);
  }

  fetchCartItems(page: number = 1) {
    return this.http.get(`${API_ENDPOINTS.CART}/cart-items?page=${page}`)
    .then((res) => res.data);
  }

  deleteAllCart() {
    return this.http
      .delete(API_ENDPOINTS.DELETE_ALL_CART)
      .then((res) => res.data);
  }

  deleteCartItem({ id }: { id: number }) {
    var url = `${API_ENDPOINTS.DELETE_CART_ITEM}/${id}`;
    return this.http
      .delete(url)
      .then((res) => res.data);
  }

  deleteCartShops(input: DeleteCartShopType) {
    return this.http
      .delete(API_ENDPOINTS.DELETE_CART_SHOPS, { data: input })
      .then((res) => res.data);
  }

  updateItemProductCart(input: UpdateItemInputType) {
    return this.http
      .post(API_ENDPOINTS.UPDATE_CART_ITEM, input)
      .then((res) => res.data);
  }

  updateCartItemPrice(params: UpdateCartItemInputType) {
    return this.http
      .post(`${API_ENDPOINTS.UPDATE_CART_ITEM_PRICE}/${params.id}`, {
      })
      .then((res) => res.data);
  }

  updateCartItemTax(params: UpdateCartItemInputType) {
    return this.http
      .post(`${API_ENDPOINTS.UPDATE_CART_ITEM_TAX}/${params.id}`, {
      })
      .then((res) => res.data);
  }

  updateNotesShop(params: UpdateNotesShopInputType) {
    return this.http
      .post(`${API_ENDPOINTS.UPDATE_NOTES_SHOP}/${params.id}`, {
        cart_id: params.id,
        cart_shops: params.cart_shops
      })
      .then((res) => res.data);
  }

  updateAllCartItemPrice() {
    return this.http
      .post(API_ENDPOINTS.UPDATE_ALL_CART_ITEM_PRICE)
      .then((res) => res.data);
  }

  updateAllCartItemChangeTax() {
    return this.http
      .post(API_ENDPOINTS.UPDATE_ALL_CART_ITEM_CHANGE_TAX)
      .then((res) => res.data);
  }

  updateUpdateAllProductMaxQuantity(params: any) {
    return this.http
      .post(API_ENDPOINTS.UPDATE_ALL_PRODUCT_MAX_QUANTITY, params)
      .then((res) => res.data);
  }
  deleteCartProductsOutOfStock() {
    return this.http
      .delete(API_ENDPOINTS.DELETE_CART_PRODUCTS_OUT_OF_STOCK)
      .then((res) => res.data);
  }

  deleteCartProductsChangedPrice() {
    return this.http
      .delete(API_ENDPOINTS.DELETE_CART_PRODUCTS_CHANGED_PRICE)
      .then((res) => res.data);
  }

  checkout(input: any) {
    return this.http
      .post(API_ENDPOINTS.CHECKOUT, input)
      .then((res) => res.data);
  }

  deleteAllSoftCartItem() {
    return this.http
      .delete(API_ENDPOINTS.DELETE_ALL_SORT_CART_ITEM)
      .then((res) => res.data);
  }

  fetchOrderProductDeleted(orderId: any) {
    return this.http.get(`${API_ENDPOINTS.FETCH_ORDER_PRODUCT_DELETED}/${orderId}?page=1&limit=1000000`)
    .then((res) => res.data);
  }

  fetchGifts() {
    return this.http.get(`${API_ENDPOINTS.FETCH_GIFTS}?page=1&limit=1000000`)
    .then((res) => res.data);
  }

  fetchGiftQualified() {
    return this.http.get(`${API_ENDPOINTS.GIFT_QUALIFIED}`)
    .then((res) => res.data);
  }

  addGiftToCart(params: any) {
    return this.http
      .post(`${API_ENDPOINTS.ADD_GIFT_TO_CART}`, {
        cartId: params.cartId,
        giftId: params.giftId
      })
      .then((res) => res.data);
  }

  deleteAllCartItemCloseShop() {
    return this.http
      .delete(API_ENDPOINTS.DELETE_ALL_CART_ITEM_CLOSE_SHOP)
      .then((res) => res.data);
  }

  deleteAllCartItemHideShop() {
    return this.http
      .delete(API_ENDPOINTS.DELETE_ALL_CART_ITEM_HIDE_SHOP)
      .then((res) => res.data);
  }

  deleteAllCartItemChangeTax() {
    return this.http
      .delete(API_ENDPOINTS.DELETE_ALL_CART_ITEM_CHANGE_TAX)
      .then((res) => res.data);
  }
}

export const MiniCartService = new Cart(API_ENDPOINTS.FETCH_CART_PRODUCT);
export const CartProductsChangedPrice = new Cart(API_ENDPOINTS.FETCH_CART_PRODUCTS_CHANGED_PRICE);
export const SuggestedProductsService = new Cart(API_ENDPOINTS.FETCH_RELATED_PRODUCTS_CART);
export const CartOutOfStockProduct = new Cart(API_ENDPOINTS.FETCH_OUT_OF_STOCK_PRODUCTS);
export const DeletedProductsInCartService = new Cart(API_ENDPOINTS.FETCH_DELETED_PRODUCTS_IN_CART);
export const GiftProgramService = new Cart(API_ENDPOINTS.FETCH_GIFTS);
export const CartItemCloseShopService = new Cart(API_ENDPOINTS.FETCH_CART_ITEM_CLOSE_SHOP);
export const CartItemHideShopService = new Cart(API_ENDPOINTS.FETCH_CART_ITEM_HIDE_SHOP);
export const CartItemChangeTaxService = new Cart(API_ENDPOINTS.FETCH_CART_ITEM_CHANGE_TAX);
