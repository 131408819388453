import { ROUTES } from '@lib/routes';
import axios from 'axios';
import Cookies from 'js-cookie';
import { getToken } from './get-token';
import { AUTH_TOKEN } from '@lib/constants';
import router from 'next/router';

const request = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REST_API_ENDPOINT, // TODO: take this api URL from env
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Change request data/error here
request.interceptors.request.use(
  (config) => {
    const token = getToken();
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token ? token : ''}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403) ||
      (error.response &&
        error.response.data.message === "PICKBAZAR_ERROR.NOT_AUTHORIZED")
    ) {
      // Cookies.remove(AUTH_TOKEN);
      // window.location.href = ROUTES.HOME;
    }

    if(error.response && error.response.status === 404) {
      router.push(ROUTES.NOT_FOUND);
    }

    return Promise.reject(error);
  }
);

export default request;
