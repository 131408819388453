import { fetchMe } from '@framework/auth/use-user';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';

interface authenticationState {
  isAuthorize: boolean;
  currentUser: any;
}

const initialState: authenticationState = {
  isAuthorize: false,
  currentUser: {}
}

export const updateUser = createAsyncThunk('users/update', async () => {
  const { me } = await fetchMe();

  return { currentUser: me }
})

export const authenticationSettingsSlice = createSlice({
  name: 'authenticationSettings',
  initialState,
  reducers: {
    authenticationSettingsClear: () => initialState,
    setIsAuthorize: (state, action) => {
      state.isAuthorize = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.authenticationSettings,
      };
    },
    [updateUser.fulfilled as unknown as string]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
})

export const {
  setIsAuthorize,
  setCurrentUser
} = authenticationSettingsSlice.actions

export default authenticationSettingsSlice.reducer
