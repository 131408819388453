import { createSlice } from '@reduxjs/toolkit';

type InitStep = {
  user_type: string;
  is_retail: number;
}

type SelectSpecializeStep = {
  customer_specialize: string[] | number[];
  store_service_provide: string[] | number[];
}

type InformationStep = {
  register_name: string;
  name: string;
  email: string;
  phone_number: string;
  password: string;
  password_confirmation: string;
}

type CustomerAddress = {
  address_detail: string;
  psc: string;
  city: string;
  country: string;
};

type FinishStep = {
  number_ico: string;
  number_dic: string;
  note: string;
  is_retail: boolean;
}

type StoreOwnerAddress = {
  address_detail: string;
  psc: string;
  city: string;
  address_detail_store: string;
  psc_store: string;
  city_store: string;
};

interface RegisterState {
  currentStep: string;
  initStep: InitStep;
  selectSpecializeStep: SelectSpecializeStep;
  informationStep: InformationStep;
  customerAddressStep: CustomerAddress;
  storeOwnerAddressStep: StoreOwnerAddress;
  finishStep: FinishStep;
}

const findStep: {[key: string]: string} = {
  user_type: "init-step",
  is_retail: "init-step",
  customer_specialize: "select-specialize-step",
  store_service_provide: "select-specialize-step",
  register_name: "information-step",
  name: "information-step",
  email: "information-step",
  phone_number: "information-step",
  password: "information-step",
  password_confirmation: "information-step",
  address_detail: "address-step",
  psc: "address-step",
  city: "address-step",
  country: "address-step",
  address_detail_store: "address-step",
  psc_store: "address-step",
  city_store: "address-step",
  number_ico: "finish-step",
  number_dic: "finish-step",
  note: "finish-step",
}

const initialState: RegisterState = {
  currentStep: 'init-step',
  initStep: {
    user_type: "",
    is_retail: 0,
  },
  selectSpecializeStep: {
    customer_specialize: [],
    store_service_provide: [],
  },
  informationStep: {
    register_name: "",
    name: "",
    email: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
  },
  customerAddressStep: {
    address_detail: "",
    psc: "",
    city: "",
    country: "Czech Republic",
  },
  storeOwnerAddressStep: {
    address_detail: "",
    psc: "",
    city: "",
    address_detail_store: "",
    psc_store: "",
    city_store: "",
  },
  finishStep: {
    number_ico: "",
    number_dic: "",
    note: "",
    is_retail: false,
  },
}

export const steps = ['init-step', 'select-specialize-step', 'information-step', 'address-step', 'finish-step', 'confirmation-step'];

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    clear: () => initialState,
    backStep: (state) => {
      const currentStep = state.currentStep,
            userType = state.initStep.user_type,
            stepIndex = steps.indexOf(currentStep);

      if(userType == "retail_customer" && currentStep == 'information-step') {
        state.currentStep = steps[stepIndex - 2];
      } else {
        state.currentStep = steps[stepIndex - 1];
      }
    },
    nextStep: (state) => {
      const currentStep = state.currentStep,
            userType = state.initStep.user_type,
            stepIndex = steps.indexOf(currentStep);
      if(stepIndex >= steps.length - 1) return;
      if(userType == "retail_customer" && currentStep == 'init-step') {
        state.currentStep = steps[stepIndex + 2];
      } else {
        state.currentStep = steps[stepIndex + 1];
      }
    },
    setInformationData: (state, action) => {
      const { data, key } = action.payload;
      if(key) {
        // @ts-ignore
        state[key] = Object.assign(state[key], data);
      } else {
        state = Object.assign(state, data);
      }
    },
    setStep: (state, action) => {
      state.currentStep = findStep[action.payload]
    }
  },
})

export const {
  clear,
  backStep,
  nextStep,
  setStep,
  setInformationData,
} = registerSlice.actions

export default registerSlice.reducer
